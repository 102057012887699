/* eslint-disable react-hooks/exhaustive-deps */
import VStockDropdown from "components/v-stock";
import { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import { Button, Column, DataTable } from "primereact";
import Users from "services/users";
import { renderAmount } from "utils/render";
import AllotmentService from "services/allotments";
import { Toast } from "primereact/toast";
import { formatNumber, showToast } from "utils/common";
import VConfirm from "components/v-confirm";

const TakeBackAllotments = () => {
  const defaultFilter = {
    start: null,
    end: null,
    stockFrom: null,
    status_kyc: null,
  };
  const defaultLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    sortField: "amount",
    sortOrder: -1,
  };
  const [globalFilter, setGlobalFilter] = useState(defaultFilter);

  const [lazyParams, setLazyParams]: any = useState(defaultLazyParams);
  const [totalRecords, setTotalRecords] = useState(0);

  const [checkUserResults, setCheckUserResults] = useState([]);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [loadingTakeBack, setLoadingTakeBack] = useState(false);
  const [sum, setSum] = useState(0);

  const toast = useRef(null);
  const initLoadedRef = useRef(true);
  const refDialogTackBack = useRef(null);

  useEffect(() => {
    if (!initLoadedRef.current) {
      onCheckUsers();
    }
    initLoadedRef.current = false;
  }, [lazyParams]);

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (value, name) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clear = () => {
    setGlobalFilter(defaultFilter);
    setCheckUserResults([]);
    setTotalRecords(0);
  };

  const onSearch = useCallback(debounce(onFilter, 500), []);

  const onCheckUsers = () => {
    try {
      setLoadingCheck(true);
      Users.getUserAllotmentAvailableToken({
        body: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          type: "rate",
        },
      })
        .then((res: any) => {
          if (res && res.docs) {
            setSum(res.sum);
            setCheckUserResults(res.docs);
            setTotalRecords(res.totalDocs);
          } else setCheckUserResults([]);
        })
        .finally(() => setLoadingCheck(false));
    } catch (error) {}
  };

  const confirmTackBack = () => {
    refDialogTackBack.current.show();
  };

  const handleTakeBack = async () => {
    try {
      setLoadingTakeBack(true);
      await AllotmentService.tackBack({
        params: {
          stock: globalFilter.stockFrom,
        },
      });
      onCheckUsers();
      setLoadingTakeBack(false);
      refDialogTackBack.current.close();
      showToast(toast, "success", "Take Back Allotments Success!");
    } catch (error) {
      setLoadingTakeBack(false);
      showToast(toast, "error", error?.errors || "");
    }
  };

  const handlePercentAmount = (amountBalance) => {
    return +((amountBalance / sum) * 100);
  };

  return (
    <div className="grid">
      <Toast ref={toast}></Toast>
      <div className="col-12 flex flex-column align-items-center justify-content-center">
        <div className="flex w-full md:w-30rem">
          <p className="text-left w-full ">Stock Apply</p>
        </div>
        <div className="flex align-items-center justify-content-center w-full md:w-30rem">
          <VStockDropdown
            value={globalFilter.stockFrom}
            setValue={(v) => {
              onSearch(v, "stockFrom");
            }}
            className="w-full"
          />
        </div>
      </div>
      <div className="col-12 md:col-12 flex flex-column md:flex-row align-items-center justify-content-center">
        <Button
          icon="pi pi-check"
          type="button"
          label="Check"
          className="p-button-success mt-2 md:mt-0 md:mr-2"
          loading={loadingCheck}
          disabled={!globalFilter.stockFrom}
          onClick={(_) => onCheckUsers()}
        />
        <Button
          type="button"
          label="Clear"
          icon="pi pi-times"
          onClick={clear}
          className="mt-2 md:mt-0 md:mr-2"
        />
        <Button
          icon="pi pi-sync"
          type="button"
          label="Take Back"
          className="p-button-danger mt-2 md:mt-0"
          loading={loadingTakeBack}
          disabled={!globalFilter.stockFrom}
          onClick={(_) => confirmTackBack()}
        />
      </div>

      <div className="col-12">
        <DataTable
          value={checkUserResults}
          paginator
          dataKey="_id"
          emptyMessage="No data found."
          showGridlines
          lazy
          responsiveLayout="scroll"
          first={lazyParams.first}
          rows={lazyParams.rows}
          totalRecords={totalRecords}
          rowsPerPageOptions={[10, 20, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} - {last} of {totalRecords}"
          onPage={onPage}
          sortField={lazyParams.sortField}
          sortOrder={lazyParams.sortOrder}
          onSort={onSort}
        >
          <Column
            field="id"
            header="User ID"
            body={(rowData) => <span>{rowData.user?.refer_code || ""}</span>}
          ></Column>
          <Column
            field="email"
            header="Email"
            body={(rowData) => <span>{rowData.user?.email || ""}</span>}
          ></Column>
          <Column
            field="token"
            header="Token"
            body={(rowData) => String(rowData?.token).toUpperCase()}
          ></Column>
          <Column
            field="amount"
            header="Amount"
            sortable
            body={(rowData) =>
              rowData.balances[checkUserResults[0]?.token]
                ? renderAmount(rowData.balances[checkUserResults[0]?.token])
                : ""
            }
          ></Column>
          <Column
            field=""
            header="Percent"
            body={(rowData) =>
              rowData.balances[checkUserResults[0]?.token] ? (
                <span>
                  {formatNumber(
                    handlePercentAmount(
                      rowData.balances[checkUserResults[0]?.token]
                    )
                  )}
                  %
                </span>
              ) : (
                ""
              )
            }
          ></Column>
        </DataTable>
      </div>
      <VConfirm
        ref={refDialogTackBack}
        onConfirm={handleTakeBack}
        message={`Are you sure you want to take back ${globalFilter.stockFrom?.toUpperCase()} allotments?`}
      />
    </div>
  );
};

export default TakeBackAllotments;
