/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { Button, InputSwitch, InputText, Panel } from "primereact";
import React, { useEffect, useImperativeHandle, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import Draggable from "react-draggable";
import { nanoid } from "nanoid";

const Details = (props, ref) => {
  const { data, toast, reload, setLoading } = props;
  const [details, setDetails] = useState(null);

  const getDefaultData = () => {
    return {
      id: nanoid(),
      name: "",
      front: {
        background: "",
        logo: {
          src: "",
          position: {
            x: 0,
            y: 0,
          },
        },
        userInputs: [],
      },
      back: {
        background: "",
        logo: {
          src: "",
          position: {
            x: 0,
            y: 0,
          },
        },
        userInputs: [],
      },
    };
  };

  const formatString = (input: string): string => {
    return input.replace(/(.{4})/g, "$1 ");
  };

  useEffect(() => {
    if (props.details) {
      setDetails((prev) => ({
        ...prev,
        ...props.details,
      }));
    } else {
      setDetails(getDefaultData());
    }
  }, [props.details]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      setLoading(true);
      if (!data) {
        return await SettingService.createSetting({
          body: {
            name: "bank_cards",
            value: [details],
          },
        });
      } else {
        if (data.length === 0) {
           await SettingService.updateSetting({
            body: {
              name: "bank_cards",
              value: [details],
            },
          });
        } else {
          let _data = [];
          if (props.details) {
            _data = data.map((i) => (i.id === details.id ? details : i));
          } else {
            _data = [...data, details];
          }
            await SettingService.updateSetting({
            body: {
              name: "bank_cards",
              value: _data,
            },
          });
        }
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
      setDetails(null);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const handleAddField = (type: string) => {
    setDetails((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        userInputs: prev[type].userInputs?.concat({
          id: uuidv4(),
          label: "",
          value: "",
          placeholder: "",
          isEdit: false,
          font_size: "15",
          color: "#000",
          font_weight: "500",
          position: {
            x: 200,
            y: 150,
          },
        }),
      },
    }));
  };

  const onChange = (value, name, type = null, field = null) => {
    let _details = { ...details };
    if (type) {
      if (field) {
        _details[type][field][`${name}`] = value;
      } else {
        _details[type][`${name}`] = value;
      }
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const handleChangeField = (name, value, type?, id?) => {
    setDetails((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        userInputs: prev[type].userInputs.map((p) =>
          p.id === id
            ? {
                ...p,
                [name]: value,
              }
            : p
        ),
      },
    }));
  };

  const handleRemoveField = (type: string, item: any) => {
    setDetails((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        userInputs: prev[type].userInputs.filter(
          (el: any) => el.id !== item.id
        ),
      },
    }));
  };

  const renderPreview = (type: string) => {
    return (
      <div className="field col-12">
        <Panel header={`${type}`} toggleable className="my-2">
          <div className="w-full flex flex-column align-items-center">
            <label
              htmlFor="preview"
              className="text-center font-bold text-md w-full"
            >
              Preview
            </label>
            <div
              className="relative"
              style={{ width: "486px", height: "306px", borderRadius: "16px" }}
            >
              <img
                className="absolute top-0 left-0 w-full h-full pointer-events-none"
                style={{ objectFit: "cover", borderRadius: "16px" }}
                src={details?.[type]?.background}
                alt=""
              />
              <Draggable
                bounds="parent"
                position={details?.[type]?.logo?.position}
                onDrag={(e, pos) =>
                  onChange({ x: pos.x, y: pos.y }, "position", type, "logo")
                }
              >
                <div className="w-2rem h-2rem" style={{ position: "absolute" }}>
                  <img
                    className="max-w-full max-h-full object-cover"
                    src={details?.[type]?.logo?.src}
                    alt="logo"
                  />
                </div>
              </Draggable>

              {details?.[type]?.userInputs?.map((item, index) => (
                <Draggable
                  key={item.id}
                  bounds="parent"
                  position={item?.position}
                  onDrag={(e, pos) =>
                    handleChangeField(
                      "position",
                      {
                        x: pos.x,
                        y: pos.y,
                      },
                      type,
                      item.id,
                    )
                  }
                >
                  <div
                    contentEditable
                    style={{
                      fontSize: `${item?.font_size}px`,
                      color: `${item?.color}`,
                      fontWeight: `${item?.font_weight}`,
                      display: "inline-block",
                      border: "none",
                      position: "absolute",
                      zIndex: index + 1,
                      background: `linear-gradient(90deg, ${item?.color}, ${item?.color})`,
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                    onInput={(e) =>
                      handleChangeField(
                        "placeholder",
                        e.currentTarget.textContent || "",
                        type,
                        item.id
                      )
                    }
                  >
                    {item.label === "Card number"
                      ? formatString(item?.placeholder)
                      : item.placeholder}
                  </div>
                </Draggable>
              ))}
            </div>
          </div>
          <div className="field col-12 md:col-12">
            <Panel header="User input" toggleable className="my-2">
              {details?.[type]?.userInputs?.map((item, index) => (
                <Panel
                  key={index}
                  header={() => (
                    <div className="flex align-items-center justify-content-between gap-2">
                      <span className="font-bold">{item.placeholder}</span>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-sm p-button-warning"
                        onClick={() => handleRemoveField(type, item)}
                      />
                    </div>
                  )}
                  toggleable
                  className="my-2"
                >
                  <div className="grid">
                    <div className="field md:col-4 col-12">
                      <label htmlFor="label">Label</label>
                      <InputText
                        id="label"
                        value={item.label}
                        onChange={(e) =>
                          handleChangeField(
                            "label",
                            e.target.value,
                            type,
                            item.id,
                          )
                        }
                        required
                      />
                    </div>
                    <div className="field md:col-4 col-12">
                      <label htmlFor="placeholder">Placeholder</label>
                      <InputText
                        id="placeholder"
                        value={item.placeholder}
                        onChange={(e) =>
                          handleChangeField(
                            "placeholder",
                            e.target.value,
                            type,
                            item.id,
                          )
                        }
                        required
                      />
                    </div>
                    <div className="field md:col-4 col-12">
                      <label htmlFor="color">Color</label>
                      <br />
                      <input
                        id="color"
                        type="color"
                        value={item?.color}
                        onChange={(e) =>
                          handleChangeField(
                            "color",
                            e.target.value,
                            type,
                            item.id
                          )
                        }
                        required
                      />
                    </div>
                    <div className="field md:col-4 col-12">
                      <label htmlFor="font_size">Font size</label>
                      <InputText
                        id="font_size"
                        value={item?.font_size}
                        onChange={(e) =>
                          handleChangeField(
                            "font_size",
                            e.target.value,
                            type,
                            item.id,
                          )
                        }
                        required
                      />
                    </div>
                    <div className="field md:col-4 col-12">
                      <label htmlFor="font_weight">Font weight</label>
                      <InputText
                        id="font_weight"
                        value={item?.font_weight}
                        onChange={(e) =>
                          handleChangeField(
                            "font_weight",
                            e.target.value,
                            type,
                            item.id,
                          )
                        }
                        required
                      />
                    </div>

                    <div className="field md:col-4 col-12">
                      <label htmlFor="isEdit">Is edit</label>
                      <br />
                      <InputSwitch
                        checked={item?.isEdit}
                        onChange={(e: any) =>
                          handleChangeField("isEdit", e.value, type, item.id)
                        }
                      />
                    </div>
                  </div>
                </Panel>
              ))}

              <div className="col-12">
                <Button
                  icon="bx bxs-add-to-queue"
                  className="p-button-rounded p-button-success"
                  aria-label="Search"
                  onClick={() => handleAddField(type)}
                />
              </div>
            </Panel>
          </div>
        </Panel>
      </div>
    );
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="description" className="font-semibold">
              Bank name
            </label>
            <InputText
              id="name"
              value={details?.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="frontBackground" className="text-center w-full">
              Front background
            </label>
            <VUpload
              urlFile={details?.front?.background}
              setUrlFile={(e) => onChange(e, "background", "front")}
              size="243x153"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="logo" className="text-center w-full">
              Front logo
            </label>
            <VUpload
              urlFile={details?.front?.logo?.src}
              setUrlFile={(e) => onChange(e, "src", "front", "logo")}
              size="42x42"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="backBackground" className="text-center w-full">
              Back background
            </label>
            <VUpload
              urlFile={details?.back?.background}
              setUrlFile={(e) => onChange(e, "background", "back")}
              size="243x153"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="logo" className="text-center w-full">
              Back logo
            </label>
            <VUpload
              urlFile={details?.back?.logo?.src}
              setUrlFile={(e) => onChange(e, "src", "back", "logo")}
              size="42x42"
            />
          </div>

          <div className="field col-12">
            {renderPreview("front")}
            {renderPreview("back")}
          </div>
        </div>
      </Panel>
    </>
  );
};

export default React.forwardRef(Details);
