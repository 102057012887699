import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: { query: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/allotments/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllotments = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/allotments`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createAllotments = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/allotments`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createAllotmentPerson = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/allotments/person`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const allotmentWithPercentOrQnt = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/allotments/alm-percent-qnt`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const exportData = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post(`admin/allotments/exports`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

  const tackBack = (req: { params: { stock: string } }) =>
    new Promise((resolve, reject) => {
      request()
        .post(`admin/finances/take-back-balances/${req.params.stock}`)
        .then((res) => {
          const { data } = res.data;
          if (data) resolve(data);
          else reject(new Error("Something went error!"));
        })
        .catch(parseErrorResponse)
        .then(reject);
    });

const AllotmentService = {
  search,
  getAllotments,
  createAllotments,
  createAllotmentPerson,
  allotmentWithPercentOrQnt,
  exportData,
  tackBack,
};

export default AllotmentService;
