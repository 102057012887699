/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import debounce from "lodash.debounce";
import MenuService from "services/menus";
import { MENU_STATUS } from "utils/enum";
import { TreeTable } from "primereact/treetable";
// import CategoryFilterService from "services/category-filters";
import StatusEditorCell from "components/v-edit-cell/StatusEditorCell";
import NumberEditorCell from "components/v-edit-cell/NumberEditorCell";
import { Panel } from "primereact/panel";
import Service from "services/categories-groups";
import { Dropdown } from "primereact/dropdown";

const Crud = () => {
  const [treeData, setTreeData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [position, setPosition] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    position: null,
    status: MENU_STATUS.ENABLE,
    parent_id: null,
  });
  const [allData, setAllData] = useState([]);

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
    getAll();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    getPosition();
  }, []);

  const getPosition = async () => {
    await Service.getAll().then((res: any) => {
      setPosition(res);
    });
  };

  const loadData = async () => {
    try {
      const res: any = await MenuService.getAllAdmin({
        query: {
          isTree: true,
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
          limit: 99999,
        },
      });
      if (res) {
        setTreeData(res);
      } else {
        setTreeData([]);
      }
    } catch (error) {
      setTreeData([]);
    }
  };
  const getAll = async () => {
    try {
      const res: any = await MenuService.getAll();
      if (res) setAllData(res);
      else setAllData([]);
    } catch (error) {
      setAllData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data, key: data.keyVal });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };
  const handleDelete = async () => {
    try {
      await MenuService.deleted({
        params: {
          id: detail._id,
        },
      });
      const _data = treeData.filter((val) => val._id !== detail._id);
      setTreeData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
      loadData();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const onChangeStatus = async (options, rowData, value) => {
    let updateRow;
    let newData = treeData.map((e) => {
      if (e._id === rowData?._id) {
        e[options.field] = value;
        updateRow = e;
      }
      return e;
    });
    setTreeData(newData);
    if (updateRow) {
      await MenuService.update({
        params: { id: updateRow._id },
        body: {
          ...updateRow,
        },
      });
    }
    loadData();
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="grid px-2 align-items-center">
        <div className="col-12 md:col-6">
          <span className="block p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
              className="w-full"
            />
          </span>{" "}
        </div>
        <div className="col-12 md:col-6">
          <Dropdown
            value={globalFilter.status}
            options={Object.keys(MENU_STATUS).map((k) => ({
              label: k,
              value: MENU_STATUS[k],
            }))}
            onChange={(e: any) => onSearch("status", e.value)}
            optionLabel="label"
            placeholder="Status"
            className={"w-full"}
            showClear
          />
        </div>
        <div className="col-12 md:col-12">
          <Dropdown
            value={globalFilter.position}
            className={"w-full"}
            options={position.map((k) => ({
              label: k?.key,
              value: k?.key,
            }))}
            onChange={(e) => onSearch("position", e.value)}
            optionLabel="value"
            optionValue="value"
            filter
            showClear
            placeholder="Position"
          />
        </div>
      </div>
    </Panel>
  );

  const iconBody = (rowData: any) => {
    return (
      <>
        {rowData.img_icon ? (
          <div>
            <img
              src={`${rowData.img_icon}`}
              alt={rowData.img_icon}
              style={{ width: "20px" }}
            />
          </div>
        ) : (
          <React.Fragment>
            <i style={{ textAlign: "center" }} className={rowData.icon}>
              &nbsp;
            </i>
          </React.Fragment>
        )}
      </>
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            // paginator
            // rowsPerPageOptions={[5, 10, 25]}
            // emptyMessage="No data found."
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            // totalRecords={totalRecords}
            resizableColumns
            columnResizeMode="expand"
            scrollable
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, width: "16%" }}
            ></Column>
            <Column
              expander
              field="name"
              header="Name"
              sortable
              body={(rowData) => <span>{rowData.name}</span>}
              style={{ flexGrow: 1, width: "25%" }}
            ></Column>
            {/* <Column
              field="type"
              header="Type"
              body={(rowData) => <span>{rowData.type}</span>}
              style={{ flexGrow: 1, width: "15%" }}
            ></Column> */}
            <Column
              field="position"
              header="Position"
              body={(rowData) => <span>{rowData.position}</span>}
              style={{ flexGrow: 1, width: "15%" }}
            ></Column>

            <Column
              body={iconBody}
              header="Icon"
              style={{ flexGrow: 1, width: "15%" }}
            ></Column>
            <Column
              field="order"
              sortable
              header="Order"
              body={(rowData, options) => (
                <NumberEditorCell
                  value={rowData.order}
                  options={options}
                  onChange={(e, value) => onChangeStatus(e, rowData, value)}
                />
              )}
              style={{ flexGrow: 1, width: "15%" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              style={{ flexGrow: 1, width: "15%" }}
              body={(rowData, options) => (
                <StatusEditorCell
                  value={rowData.status}
                  options={options}
                  rowData={rowData}
                  onChange={onChangeStatus}
                />
              )}
            ></Column>
          </TreeTable>
          <VDialog
            ref={refDialogDetail}
            header="Menu Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              treeMenus={treeData}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
              position={position}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Crud, comparisonFn);
