/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";

import ListAllotments from "./components/Tables";
import ImportExcel from "./components/ImportExcel";
import Airdrop from "./components/Airdrop";
import TakeBackAllotments from "./components/TakeBackAllotments";

const View = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <div className="pb-3">
            <h4>Allotments</h4>
          </div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="List Allotments">
              <ListAllotments />
            </TabPanel>
            <TabPanel header="Import Excel">
              <ImportExcel />
            </TabPanel>
            <TabPanel header="Airdrop">
              <Airdrop />
            </TabPanel>
            <TabPanel header="Take Back">
              <TakeBackAllotments />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
